import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Pagination,
    Stack,
    Select,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Unstable_Grid2 as Grid,
} from '@mui/material';
import { PostCard } from './PostCard';
import makeRequest from '../../makeRequest';
import MUIModal from '../../components/MUIModal';
import EditPost from './EditPost';
import { removeNonValueFilters } from '../../helpers';
import Loader from '../../components/Loader';
import { useDataContext } from '../../context/DataContext';

const PostList = () => {
    const { postStatusList } = useDataContext();
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [posts, setPosts] = useState([]);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const page = parseInt(existingParams.page || 1, 10);
    const status = existingParams.status || '';
    const [pagination, setPagination] = useState({ page, pageCount: 1 });
    const perPage = 12;

    const handleModalClose = () => {
        setSelectedPost(null);
        setShowForm(false);
    };

    const fetchPostData = async () => {
        setIsLoading(true);
        const filters = status ? `&filters[$and][0][status][$eq]=${status}` : '';
        try {
            const respData = await makeRequest({
                method: 'GET',
                url: `/api/posts?populate=*&publicationState=preview&populate=channel.product&populate=media&sort=createdAt:desc${filters}&pagination[page]=${page}&pagination[pageSize]=${perPage}`,
            });
            setPosts(respData?.data || []);
            setPagination(respData?.meta?.pagination || { page: 1, pageCount: 1 });
        } catch (err) {
            console.error(`Error fetching post stats:`, err);
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPostData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, status]);

    const updateQueryParams = (params) => {
        const updatedParams = removeNonValueFilters({ ...existingParams, ...params });
        setQueryParams(updatedParams);
    };

    const handlePageChange = (event, value) => {
        updateQueryParams({ page: value });
    };

    const handleFilterChange = (event) => {
        updateQueryParams({ status: event.target.value, page: 1 });
    };
    const handleDelete = async (postId, refreshPosts) => {
        try {
          // Construct the API endpoint
          const endpoint = `/api/posts/${postId}`;
      
          // Make the DELETE request
          await makeRequest(
            {
              method: 'DELETE',
              url: endpoint,
            },
            true // Ensure the request includes authentication
          );
      
          // Show success message or take appropriate action
          console.log(`Post with ID ${postId} deleted successfully.`);
      
          // Refresh the list of posts
          if (typeof refreshPosts === 'function') {
            await refreshPosts();
          }
        } catch (error) {
          console.error(`Failed to delete post with ID ${postId}:`, error.message);
          // Handle the error, e.g., show an error notification to the user
        }
      };
      
    const refreshPosts = async () => {
        setLoading(true);
        try {
          await fetchPostData();
          console.log('Posts refreshed successfully.');
        } catch (error) {
          console.error('Error refreshing posts:', error.message);
        } finally {
          setLoading(false);
        }
      };

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, py: 4 }}>
                {loading && <Loader />}
                {!loading && (
                    <>
                        <Stack spacing={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 3,
                                }}
                            >
                                <Typography variant="h5">Posts</Typography>
                                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                                    <TextField
                                        select
                                        value={status}
                                        onChange={handleFilterChange}
                                        label="Status"
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {postStatusList.map((statusItem) => (
                                            <MenuItem
                                                key={statusItem.value}
                                                value={statusItem.value}
                                            >
                                                {statusItem.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>

                            {posts.length === 0 ? (
                                <Box
                                    component="div"
                                    display="flex"
                                    justifyContent="center"
                                    sx={{ textAlign: 'center' }}
                                    width="100%"
                                    my={4}
                                >
                                    <Typography variant="subtitle2">No data available</Typography>
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2}>
                                        {posts.map((postData) => (
                                            <Grid xs={12} md={6} lg={4} key={postData.id}>
                                                <PostCard
                                                    postData={postData}
                                                    dataType="posts"
                                                    onEdit={(eData) => {
                                                        setSelectedPost(eData.id);
                                                        setShowForm(true);
                                                    }}
                                                    onDelete={(postId) => handleDelete(postId, refreshPosts)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        py={4}
                                    >
                                        <Pagination
                                            count={pagination.pageCount}
                                            page={pagination.page}
                                            onChange={handlePageChange}
                                            size="small"
                                        />
                                    </Box>
                                </>
                            )}
                        </Stack>
                    </>
                )}
            </Box>
            {selectedPost && (
                <MUIModal
                    maxWidth="md"
                    title="Edit Post"
                    open={showForm}
                    handleClose={handleModalClose}
                    dialogContentStyle={{ px: 0 }}
                >
                    <EditPost pId={selectedPost} />
                </MUIModal>
            )}
        </>
    );
};

export default PostList;
