import { useState, useEffect } from 'react';
import moment from 'moment';
import ChartCard from './ChartCard';
import makeRequest from '../../../src/makeRequest';
import { chartDefaultDateRange } from '../../helpers';


export default function Insights() {
  const initialChartData = {  };
  const [loading, setLoading] = useState(false);
  const [chartData, setInsightsChartData] = useState(initialChartData);

  async function fetchProductPublishedPosts() {
    try {
      setLoading(true);
      const chartResp = await makeRequest({
        url: `/api/post/all-product-published-post-chart-data`,
        method: 'GET',
      });

      if (chartResp?.data?.chartData) {
        const chartData = chartResp.data.chartData;
        if (chartData.series.some(series => series.data.length !== chartData.labels.length)) {
          console.error('Error: Mismatch in data lengths for series and labels');
          setInsightsChartData(initialChartData);
          return;
        }
        setInsightsChartData({
          labels: chartData.labels.map(date => moment(date).format('MM/DD/YYYY')),
          series: chartData.series,
        });
      }
    } catch (error) {
      console.error('Error fetching product stats:', error);
      setInsightsChartData(initialChartData);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProductPublishedPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        show: true,
        offsetX: 0,
        offsetY: -38,
      }
    },
    xaxis: {
      type: 'datetime',
      min: chartDefaultDateRange().startOfYear,
      max: chartDefaultDateRange().endOfYear,
    },
    dataLabels: {
      enabled: false
    },
  }

  return (
    <ChartCard
      loading={loading}
      title="Insights"
      subheader="Published posts per day"
      chart={{
        ...chartData,
        options: chartOptions
      }}
      formatTooltip={(value) => (value ? `${value.toFixed(0)} Posts` : value)}
    />
  );
}