import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ChartCard from './ChartCard';
import makeRequest from '../../makeRequest';
import { chartDefaultDateRange } from '../../helpers';


export default function ProductPostStats({ productId }) {
    const [loading, setLoading] = useState(false);
    const initialChartData = { };
    const [chartData, setChartData] = useState(initialChartData);


    const fetchProductAllPosts = async (pId) => {
        try {
            setLoading(true);
            const productAllPostRes = await makeRequest({
                url: `/api/post/product-all-posts-chart-data?product=${pId}`,
                method: 'GET',
            });
            if (!productAllPostRes) {
                console.error('Invalid API response:', productAllPostRes);
                setChartData(initialChartData);
                return;
            }

            const modifiedChartData = productAllPostRes?.data?.chartData;
            
            if (modifiedChartData.series.some(series => series.data.length !== modifiedChartData.labels.length)) {
                setChartData(initialChartData);
                return;
            }

            setChartData({
                labels: modifiedChartData.labels.map(date => moment(date).format('MM/DD/YYYY')),
                series: modifiedChartData.series,
            });
        } catch (error) {
            console.error('Failed to fetch product all post:', error);
            setChartData(initialChartData);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => { 
        if (!productId) return;
        fetchProductAllPosts(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true,
                offsetX: 0,
                offsetY: -28,
            }
        },
        xaxis: {
            type: 'datetime',
            min: chartDefaultDateRange().startOfYear,
            max: chartDefaultDateRange().endOfYear,
        },
        dataLabels: {
            enabled: false
        },
    }

    return (
        <ChartCard
            loading={loading}
            title={productId ? "Product post by status": ''}
            chart={{
                ...chartData,
                options: chartOptions
            }}
            formatTooltip={(value) => (value ? `${value.toFixed(0)} Posts` : value)}
            chartHeight={385}
        />
    );
}
