import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Box, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeRequest from '../../makeRequest';
import Loader from '../../components/Loader';
import ProductForm from './ProductForm';
import MUIModal from '../../components/MUIModal';
import MUILoader from '../../components/MUILoader';

const ProductList = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const fetchData = () => {
    setLoading(true);
    makeRequest({
      url: `/api/products?populate=*&publicationState=preview&sort[0]=id:asc`,
      method: 'GET',
    })
      .then(respData => {
        if (respData?.data) {
          setProducts(respData.data);
        }
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleEdit = (productData) => {
    setProduct(productData)
    setShowForm(true);

  };

  const handleDelete = async (id) => {
    if (!id) return;

    setLoading(true);
    await makeRequest({
      method: 'DELETE',
      url: `/api/products/${id}`,
    }).then(respData => {
      if (respData?.data) {
        fetchData();
        handleDeleteModalClose();
      }
    }).catch(err => {
      console.error(`Product delete Error`, err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleConfirmDelete = async (productData) => {
    setProduct(productData);
    setViewModal(true);
  }

  const handleProductSubmit = async (productData) => {
    const existingChannelIds = product ? product.attributes.channels.data.map(c => c.id) : [];

    const connectChannels = productData.channels.map(cItem => ({
      id: cItem.id,
      position: { end: true }
    }));

    const disconnectChannels = existingChannelIds
      .filter(id => !productData.channels.some(c => c.id === id))
      .map(id => ({ id }));

    let postData = {
      name: productData.name,
      details: productData.description,
      url: productData.url,
      channels: {
        connect: connectChannels,
        disconnect: disconnectChannels,
      },
    };

    if (product) {
      const removedFiles = productData?.removedFiles || [];
      postData = { ...postData, id: product.id, removedFiles }
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(postData));

    if (productData?.files?.length) {
      for (const file of productData.files) {
        formData.append('files', file);
      }
    }

    setIsLoading(true);
    await makeRequest({
      method: product ? 'PUT' : 'POST',
      url: product ? `/api/products/${product.id}` : '/api/products',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(respData => {
      if (respData?.data) {
        fetchData();
        handleModalClose();
      }
    }).catch(err => {
      console.error(`Product submit Error`, err);

    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleModalClose = () => {
    setShowForm(false)
    if (product) {
      setProduct(null)
    }
  }
  const handleDeleteModalClose = () => {
    setViewModal(false);
    if (product) {
      setProduct(null)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  return (
    <Box sx={{ flexGrow: 1, }}>
      <Box display="flex" sx={{ marginTop: 4, justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={() => { setShowForm(true) }}>
          Create Product
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Product</Typography></TableCell>
              {/* <TableCell><Typography variant="body1" fontWeight="bold">Details</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">URL</Typography></TableCell> */}
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Channels</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {products.length === 0 && <TableRow><TableCell colSpan={3}><Typography variant="body1" fontWeight="bold" sx={{ py: 4, textAlign: 'center' }}>Products not available. create products to proceed further.</Typography></TableCell></TableRow>}
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell align='left'>{product?.attributes.name}</TableCell>
                {/* <TableCell>{product?.attributes.details}</TableCell>
                <TableCell>
                  <a href={product?.attributes.url} target="_blank" rel="noopener noreferrer">
                    {product?.attributes.url}
                  </a>
                </TableCell> */}
                <TableCell align='center'>
                  {product?.attributes.channels?.data.length > 0 ? (product?.attributes.channels?.data.map((channel) => (
                    <Typography key={channel.id} variant="body2">
                      {channel.attributes.display_name} - ({channel.attributes.channel_type})
                    </Typography>
                  ))) : '-'}
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title="Edit Product">
                    <IconButton variant="contained" onClick={() => handleEdit(product)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Product">
                    <IconButton variant="contained" onClick={() => handleConfirmDelete(product)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MUIModal
        maxWidth='sm'
        title={product ? 'Update Product' : 'Create Product'}
        open={showForm}
        handleClose={handleModalClose}
      >
        <ProductForm product={product} onSubmit={handleProductSubmit} />
      </MUIModal>

      {isLoading && <MUILoader open />}

      <MUIModal
        open={viewModal}
        handleClose={() => {
          handleDeleteModalClose()
        }}
        title="Do you want to proceed?"
      >
        <Box pt={1}>
          <Typography variant='title'>
            {`You are about to delete the product ${product?.attributes?.name}. This action cannot be undone.`}
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} flexWrap="wrap" py={1}>
            <Button
              size="small"
              variant="outlined"
              type="submit"
              onClick={handleDeleteModalClose}
              sx={{ mt: 1 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color='error'
              variant="contained"
              type="submit"
              onClick={() => { handleDelete(product?.id) }}
              sx={{ mt: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </MUIModal>

      {error && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ProductList;
