import React, { useState, useEffect } from 'react';
import makeRequest from '../../makeRequest';
import SamplePrompt from '../../components/SamplePrompt';
import MUILoader from '../../components/MUILoader';
import { useNavigate } from 'react-router-dom';
import { formatDateToUTC, formatDateWithTimezone, validateNumberField } from '../../helpers';
import PageHeader from '../../components/PageHeader';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { PostCard } from './PostCard';
import MUIModal from '../../components/MUIModal';
import EditPost from './EditPost';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    Box,
    Container,
    Grid,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
    Typography,
    IconButton,
    CircularProgress,
    Switch
} from '@mui/material';
import Modal from '@mui/material/Modal';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Slider from 'react-slick';
import { useDataContext } from '../../context/DataContext';
import { useAuthContext } from "../../context/AuthContext";
import ManualPostCreation from "./ManualPostCreation";


const CreatePost = () => {
    const navigate = useNavigate();
    const { products, channels, medias, postTypes, loading } = useDataContext();
    const [product, setProduct] = useState('');
    const [postType, setPostType] = useState('multi-images');
    const [message, setMessage] = useState('');
    const [scheduledPosts, setScheduledPosts] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [numOfDays, setNumDays] = useState('');
    const [socialMedia, setSocialMedia] = useState('');
    const [channel, setChannel] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const filteredChannels = channels?.filter(chan => chan.attributes.product?.data?.id === product);
    const [isManual, setIsManual] = useState(false);
    const [showManualModal, setShowManualModal] = useState(false);

    const handleSwitchChange = (event) => {
        setIsManual(event.target.checked);
        if (event.target.checked) {
            setShowManualModal(true);
        }
    };

    const handleManualModalClose = () => {
        setIsManual(false);
        setShowManualModal(false);
    };

    const handleKeyDown = (event) => {
        if (!validateNumberField(event)) {
            event.preventDefault();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const startDateObj = new Date(startDate);
        // const utcStartDate = formatDateToUTC(startDateObj);
        const startDateZone = formatDateWithTimezone(startDateObj);
        const postData = {
            product,
            channel,
            socialMedia,
            postType,
            message,
            startDate: startDateZone,
            numOfDays,
        }
        setIsLoading(true);
        await makeRequest({
            method: 'POST',
            url: '/api/chatgpt/automate-post-create',
            data: postData
        }).then(resp => {
            if (resp?.data) {
                setScheduledPosts(resp?.data?.scheduledPosts);
            }
        }).catch(err => {
            console.error(`ChatGPT request Error`, err);
        }).finally(() => {
            setIsLoading(false);
            // setTimeout(() => {
            //     navigate('/calendar')
            // }, 5000);
        });
    };


    const handleModalClose = async () => {
        if (selectedPost) {
            try {
                const response = await makeRequest({
                    method: 'GET',
                    url: `/api/posts/${selectedPost}?populate=*&publicationState=preview&populate=channel.product&populate=media&populate=scheduled_by`,
                });

                if (response?.data) {
                    const { attributes } = response?.data;
                    const channelData = attributes.channel?.data?.attributes;
                    const productData = channelData?.product?.data?.attributes;
                    const updatedPost = {
                        id: response?.data.id,
                        content: attributes.content,
                        tm_scheduled: attributes.tm_scheduled,
                        tm_posted: attributes.tm_posted,
                        channel: { ...channelData, product: productData },
                        status: attributes.status,
                        publishedAt: attributes.publishedAt,
                        postUrl: attributes.post_url,
                        scheduled_by: attributes.scheduled_by?.data?.id,
                    };

                    setScheduledPosts((prevPosts) =>
                        prevPosts.map((post) =>
                            post.id === selectedPost ? updatedPost : post
                        )
                    );
                }
            } catch (error) {
                console.error('Failed to fetch updated post data:', error);
            }
        }

        setSelectedPost(null);
        setShowForm(false);
    };

    const handleDelete = async (postId) => {
        setIsLoading(true);
        try {
          await makeRequest({
            method: 'DELETE',
            url: `/api/posts/${postId}`,
          });
          setScheduledPosts((prevPosts) =>
            prevPosts.filter((post) => post.id !== postId)
          );
        } catch (error) {
          console.error('Error deleting post:', error);
        } finally {
          setIsLoading(false);
        }
      };


    useEffect(() => {
        if (product && socialMedia) {
            const matchingChannel = filteredChannels.find(chan => chan.attributes.channel_type === socialMedia);
            if (matchingChannel) {
                setChannel(matchingChannel.id);
            } else {
                setChannel('');
            }
        }
    }, [product, socialMedia, filteredChannels]);

    if (loading) {
        return <CircularProgress color="primary" />;
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
            }}
        >
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="Schedule Post" icon={<EditCalendarIcon />} />
                    <Box display="flex" alignItems="center">
                        <Switch
                            checked={isManual}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'Manual mode switch' }}
                            color="primary"
                        />
                        <Typography variant="body1" noWrap>
                            Create Post Manually
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 4 }}>
                    {isLoading && <MUILoader open />}
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    select
                                    label="Select Product"
                                    fullWidth
                                    value={product}
                                    onChange={(e) => setProduct(e.target.value)}
                                    required
                                >
                                    {products.map((pItem) => (
                                        <MenuItem key={pItem.id} value={pItem.id}>
                                            {pItem.attributes.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    select
                                    label="Select Social Media"
                                    fullWidth
                                    value={socialMedia}
                                    onChange={(e) => setSocialMedia(e.target.value)}
                                    required
                                    disabled={!product}
                                >
                                    {medias.map((mItem) => (
                                        <MenuItem key={mItem.value} value={mItem.value}>
                                            {mItem.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    select
                                    label="Select Channel"
                                    fullWidth
                                    value={channel}
                                    onChange={(e) => setChannel(e.target.value)}
                                    required
                                    disabled={!product || !socialMedia}
                                >
                                    {filteredChannels?.map((chan) => (
                                        <MenuItem key={chan.id} value={chan.id}>
                                            {chan.attributes.display_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    select
                                    label="Select Post Type"
                                    fullWidth
                                    value={postType}
                                    onChange={(e) => setPostType(e.target.value)}
                                    required
                                >
                                    {postTypes.map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Start Date"
                                    type="datetime-local"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Number of Days"
                                    type="number"
                                    fullWidth
                                    value={numOfDays}
                                    onChange={(e) => setNumDays(Number(e.target.value))}
                                    required
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {socialMedia && numOfDays && <Box component="div" display="flex" justifyContent="flex-end" pb={1}>
                                    <SamplePrompt socialMedia={socialMedia} numOfDays={numOfDays} />
                                </Box>}
                                <TextField
                                    label="Enter your Prompt"
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="div" display="flex" justifyContent="flex-end">
                                    <Button variant="contained" type="submit" >
                                        Schedule
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                {scheduledPosts.length > 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            flexGrow: 1,
                            py: 4
                        }}>
                        <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center', my: 4 }}>
                            <CalendarMonthIcon />
                            <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                                Scheduled Posts
                            </Typography>
                        </Box>
                        <Grid
                            container
                            spacing={2}
                        >
                            {scheduledPosts.map((postData) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    key={postData.id}
                                >
                                    <PostCard
                                        postData={postData}
                                        dataType="scheduledPosts"
                                        onEdit={(eData) => {
                                            setSelectedPost(eData.id);
                                            setShowForm(true);
                                        }}
                                        onDelete={handleDelete}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                {selectedPost && <MUIModal
                    maxWidth='md'
                    title='Edit Post'
                    open={showForm}
                    handleClose={handleModalClose}
                    dialogContentStyle={{ px:0 }}
                >
                    <EditPost pId={selectedPost} cardType='new' triggerClose={handleModalClose} />
                </MUIModal>}
                <MUIModal
                    maxWidth="md"
                    title="Manual Post Creation"
                    open={showManualModal}
                    handleClose={handleManualModalClose}
                >
                    <ManualPostCreation handleClose={handleManualModalClose} />
                </MUIModal>
            </Container>
        </Box>
    );
};

export default CreatePost;
