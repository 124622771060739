import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Container, SvgIcon } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import makeRequest from '../../makeRequest';
import ProductList from './ProductList';
import ChannelList from './ChannelList';
import Tabs from '../../components/Tabs';
import { FB_APP } from '../../constant';
import Loader from '../../components/Loader';


function Settings() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState([]);
    
    const handleFacebookConnect = () => {
        const redirectUri = encodeURIComponent(`${window.location.href}`);
        const scopes = [
            'pages_show_list',
            'public_profile',
            'email',
            'business_management',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'catalog_management',
            'instagram_shopping_tag_products',
            'instagram_content_publish',
            'pages_manage_metadata',
            'instagram_manage_comments',
            'pages_manage_posts',
            'pages_manage_engagement',
            'pages_manage_instant_articles',
            'publish_video',
            'read_insights',
            // 'threads_manage_replies',
            // 'threads_read_replies',
        ];

        setLoading(true);
        const scopeStr = encodeURIComponent(scopes.join(','))
        const fbOAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?response_type=code&state=${Date.now()}&scope=${scopeStr}&display=popup&client_id=${FB_APP}&redirect_uri=${redirectUri}`;
        window.location.href = fbOAuthUrl;

    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        setLoading(true);
        if (code) {
            makeRequest({
                url: `/api/facebook?code=${code}&redirect_uri=settings?view=channels`,
                method: 'GET',
            })
                .then(response => {
                    if (response?.account) {
                        window.location.href = window.location.pathname;
                        // navigate(window.location.pathname, { replace: true });
                    }
                })
                .catch(error => {
                    console.error('Error during Facebook login:', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
        setLoading(false);
    }, [navigate]);


    const fetchData = () => {
        setLoading(true);
        makeRequest({
            url: `/api/channels?populate=*&publicationState=preview`,
            method: 'GET',
        })
            .then(respData => {
                if (respData?.data) {
                    setChannels(respData.data);
                }
    
            })
            .catch(error => {
                console.error('Error fetching channels:', error);
            }).finally(() => {
                setLoading(false);
            });
    }    
    useEffect(() => {
        fetchData();
    }, [])

    const tabs = [
        { 
            key: 'channels', 
            label: 'Channels', 
            content: loading ? <Loader/> : <ChannelList 
                channels={channels} 
                handleFacebookConnect={handleFacebookConnect}/> 
        },
        {
            key: 'products',
            label: 'Products',
            content: loading ? <Loader/> : <ProductList/>
        },
    ];


    return (
        <Box component="div">
            <Tabs
                tabs={tabs}
                defaultView="channels"
                showPageHeader
                pageTitle="Settings"
                pageIcon={<SettingsIcon />}
            />
        </Box>
    );
}

export default Settings;
