import { createContext, useContext, useEffect, useState } from "react";
import makeRequest from "../makeRequest";
import { useAuthContext } from "./AuthContext";
import { getStatusBgColor, transformArray } from "../helpers";
import {sampleData} from './sampleData'

const DataContext = createContext({});

export const useDataContext = () => useContext(DataContext);


export const DataProvider = ({ children }) => {
    const { user } = useAuthContext();
    const [products, setProducts] = useState([]);
    const [channels, setChannels] = useState([]);
    const [postTypes, setPostTypes] = useState([]);
    const [postStatusList, setPostStatusList] = useState([]);
    const [medias, setMedias] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (!user) return;
        
        const fetchData = async () => {
            try {
                const [
                    productResp,
                    postTypeResp,
                    postStatusResp,
                    channelTypeResp,
                    channelListResp
                ] = await Promise.all([
                    makeRequest({
                        method: 'GET',
                        url: '/api/products?populate=*&publicationState=preview&sort[0]=id:asc'
                    }),
                    makeRequest({
                        method: 'GET',
                        url: '/api/post/post-type-list'
                    }),
                    makeRequest({
                        method: 'GET',
                        url: '/api/post/status-list'
                    }),
                    makeRequest({
                        method: 'GET',
                        url: '/api/channels/channel-type-list'
                    }),
                    makeRequest({
                        method: 'GET',
                        url: '/api/channels?populate=*&publicationState=preview&fields[0]=id&fields[1]=channel_id&fields[2]=channel_type&fields[3]=display_name'
                    })
                ]);

                if (productResp?.data) {
                    setProducts(productResp?.data);
                }

                if (postTypeResp?.postTypeList) {
                    const transformedPostTypes = transformArray(postTypeResp.postTypeList, '-');
                    setPostTypes(transformedPostTypes);
                }

                if (postStatusResp?.statusList) {
                    const transformedPostStatus = transformArray(postStatusResp.statusList);
                    setPostStatusList(transformedPostStatus);
                }

                if (channelTypeResp?.channelTypes) {
                    const tranformedChannelTypes = transformArray(channelTypeResp.channelTypes, '_')
                    setMedias(tranformedChannelTypes);
                }

                if (channelListResp?.data) {
                    setChannels(channelListResp?.data);
                }

            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [user])

    const modifiedStatus = postStatusList.map(sItem=>{
        const backgroundColor = getStatusBgColor(sItem?.value)
        return({
            ...sItem,
            backgroundColor
        })
    })

    return (
        <DataContext.Provider value={{
            products,
            medias,
            channels,
            postTypes,
            postStatusList:modifiedStatus,
            loading,
            error,
            dummyData: sampleData
        }}>
            {children}
        </DataContext.Provider>
    )
}