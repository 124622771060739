import React, { useState, useEffect } from 'react';
import makeRequest from '../../makeRequest';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
    Typography,
    IconButton,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Slider from 'react-slick';
import { useDataContext } from '../../context/DataContext';
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';

const ManualPostCreation = ({ handleClose }) => {
    const [channel, setChannel] = useState('');
    const [product, setProduct] = useState('');
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [scheduledBy, setScheduledBy] = useState('');
    const [content, setContent] = useState('');
    const [scheduledTime, setScheduledTime] = useState(null);
    const [mediaData, setMediaData] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const { channels, postTypes, postStatusList } = useDataContext();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuthContext();
    const [updatedMedia, setUpdatedMedia] = useState([]);
    const [users, setUsers] = useState(false);
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({}); // Validation errors state

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                }}
                onClick={onClick}
            >
                ➡️
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                }}
                onClick={onClick}
            >
                ⬅️
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // rows: 1,
        arrows: !(type === 'single-image-post' || type === 'single-video-post'),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        className: 'media-slider'
    };

    useEffect(() => {
        if (user) {
            setUsers(user);
        }
    }, [user]);

    const validateFields = () => {
        const errors = {};
        if (!channel) errors.channel = "Channel is required";
        if (!scheduledTime) errors.scheduledTime = "Scheduled time is required";
        if (!content.trim()) errors.content = "Content is required";
        if (!type) errors.type = "Type is required";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleCreatePost = async () => {
        if (!validateFields()) {
            return;
        }
        if (!user) {
            console.error("User not logged in");
            return;
        }
        let mediaToUpload = [];
        if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                alert("Video files are not allowed. Please upload only images!");
                return;
            }
        } else if (['single-video-post', 'multi-videos', 'story-video'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && !media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                alert("Only video files are allowed. Please upload valid videos!");
                return;
            }
        }
        // -------- Handle Media Upload --------
        if (newMedia.length > 0) {
            const formData = new FormData();
            for (const mediaItem of newMedia) {
                formData.append('files', mediaItem?.file);
            }
            try {
                const uploadResponse = await makeRequest({
                    method: "POST",
                    url: "/api/upload",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (!uploadResponse) {
                    throw new Error("Failed to upload media.");
                }

                mediaToUpload = [...uploadResponse];
            } catch (error) {
                console.error("Media upload failed:", error);
                return;
            }
        }
        const dataToCreate = {
            channel: { connect: [{ id: channel }] },
            tm_scheduled: scheduledTime ? scheduledTime.toISOString() : null,
            content: content,
            type: type,
            status: status,
            scheduled_by: { connect: [{ id: user.id }] },
            media: mediaToUpload,
        };
        try {
            const response = await makeRequest({
                method: 'POST',
                url: '/api/posts/create-manual',
                data: dataToCreate,
            });
            setType('');
            setContent('');
            setScheduledTime(null);
            setStatus('');
            setChannel('');
            setUpdatedMedia([]);
            setNewMedia([]);
            setValidationErrors({});
            setOpenSnackbar(true);
            navigate('/schedule?view=calendar');
        } catch (error) {
            console.error('Error saving post:', error);
        }
    };

    const handleAddMedia = (event) => {
        const files = Array.from(event.target.files).filter(file => {
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type === 'video/mp4';
            if (type === 'single-video-post' || type === 'multi-video-post') {
                if (!isVideo) {
                    alert('Only MP4 videos are allowed.');
                    return false;
                }
            } else if (type === 'single-image-post' || type === 'multi-posts') {
                if (!isImage) {
                    alert('Only images are allowed.');
                    return false;
                }
            }
            return true;
        });

        if (files.length === 0) {
            return;
        }

        const formattedFiles = files.map(file => ({
            file,
            img: URL.createObjectURL(file),
        }));
        setNewMedia(prevMedia => [...prevMedia, ...formattedFiles]);
    };

    const handleDeleteMedia = (item, index) => {
        setNewMedia(prev => prev.filter(fItem => fItem.img !== item.img));
        setMediaData(prev => prev.filter((_, i) => i !== index));
    };

    const channelOptions = channels.map(channelItem => ({
        id: channelItem.id,
        label: `${channelItem.attributes.display_name} - ${channelItem.attributes.channel_type}`,
    }));

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box component="main">
            <Box>
                {isLoading && <Loader />}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            value={channel}
                            label="Channel"
                            onChange={e => setChannel(e.target.value)}
                        >
                            {channelOptions.map(channel => (
                                <MenuItem key={channel.id} value={channel.id}>
                                    {channel.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.channel && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.channel}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={type}
                            label="Type"
                            fullWidth
                            onChange={e => setType(e.target.value)}
                        >
                            {postTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.type && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.type}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Scheduled Time"
                                value={scheduledTime}
                                onChange={setScheduledTime}
                                sx={{
                                    width: '100%',
                                }}
                                slotProps={{
                                    popper: {
                                        sx: {
                                            '& .MuiPaper-root': {
                                                width: '500px', // Adjust the popup modal width
                                                // maxWidth: '500px', // Optional: Set a max width
                                                // padding: '16px', // Optional: Adjust padding inside the modal
                                                paddingBottom: 10
                                            },
                                        },
                                    },
                                }}
                            />
                            {validationErrors.scheduledTime && (
                                <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.scheduledTime}</span>
                            )}
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={status}
                            label="Status"
                            fullWidth
                            onChange={e => setStatus(e.target.value)}
                        >
                            {postStatusList.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Content"
                            multiline
                            rows={5}
                            value={content}
                            onChange={e => setContent(e.target.value)}
                            fullWidth
                        />
                        {validationErrors.content && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.content}</span>
                        )}
                    </Grid>

                    {type !== 'text' && (<Grid item xs={12} md={6}>
                        <Box>
                            <Grid justifyContent={'center'} >
                                <FormControl fullWidth>
                                    <InputLabel id="type-select-label">Media</InputLabel>
                                    <Box
                                        alignItems="center"
                                        sx={{
                                            // width: '460px',
                                            border: '1px solid lightgray',
                                            borderRadius: '4px',
                                        }}>
                                        {type === "text" ? (
                                            <Typography variant="subtitle1" color="textSecondary">
                                            </Typography>
                                        ) : mediaData.length === 0 && newMedia.length === 0 ? (
                                            <Box
                                                sx={{
                                                    height: '150px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '4px',
                                                    '&:hover .hover-actions': {
                                                        opacity: 0.9,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    className="hover-actions"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{
                                                        opacity: 1,
                                                        transition: 'opacity 0.7s ease-in-out',
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    {/* ---------Add Media--------- */}
                                                    {!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0 ? (
                                                        <IconButton
                                                            onClick={() => document.querySelector('#add-input-default').click()}
                                                            sx={{
                                                                color: 'black',
                                                                backgroundColor: 'gray',
                                                                '&:hover': { backgroundColor: 'white' },
                                                            }}
                                                        >
                                                            <AddSharpIcon />
                                                        </IconButton>
                                                    ) : null}
                                                    <input
                                                        type="file"
                                                        id="add-input-default"
                                                        hidden
                                                        // multiple
                                                        multiple={type !== 'single-video-post'}
                                                        accept={type === 'multi video post' || type === 'single-video-post' ? 'video/mp4' : '*/*'}
                                                        onChange={handleAddMedia}
                                                    />
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Slider {...settings}>
                                                {[...mediaData, ...newMedia].map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        position="relative"
                                                        sx={{
                                                            width: '100%',
                                                            height: '150px',
                                                            overflow: 'hidden',
                                                            position: 'relative',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'transparent',
                                                            '&:hover .hover-actions': {
                                                                opacity: 0.9,
                                                            },
                                                        }}
                                                    >
                                                        {item.file?.type.startsWith('video/') || item.img?.endsWith('.mp4') ? (
                                                            <video
                                                                controls
                                                                style={{
                                                                    width: '150px',
                                                                    height: '150px',
                                                                    margin: "0 auto",
                                                                    // display: 'flex',
                                                                    objectFit: 'cover',
                                                                    display: 'block',
                                                                    backgroundColor: '#fff',
                                                                }}
                                                            >
                                                                <source src={item.img} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <img
                                                                src={item.img}
                                                                alt={`media-${index}`}
                                                                style={{
                                                                    width: "150px",
                                                                    height: "150px",
                                                                    margin: "0 auto",
                                                                    objectFit: 'cover',
                                                                    display: 'block',
                                                                    backgroundColor: '#fff',
                                                                }}
                                                            />
                                                        )}

                                                        {/* -------Hover Actions------- */}
                                                        <Box
                                                            className="hover-actions"
                                                            position="absolute"
                                                            // top={0}
                                                            left={0}
                                                            right={0}
                                                            bottom={0}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            bgcolor="rgba(0, 0, 0, 0.8)"
                                                            color="#fff"
                                                            sx={{
                                                                opacity: 0,
                                                                transition: 'opacity 0.7s ease-in-out',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {/* -------Add Media ------ */}
                                                            {(!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0) && (
                                                                <IconButton
                                                                    onClick={() => document.querySelector(`#add-input-${index}`).click()}
                                                                    sx={{
                                                                        marginRight: '8px',
                                                                        color: 'black',
                                                                        backgroundColor: 'gray',
                                                                        '&:hover': { backgroundColor: 'white' },
                                                                    }}
                                                                    disabled={type === 'single-image-post' || type === 'single-video-post' ? (mediaData.length + newMedia.length) === 1 : false}
                                                                >
                                                                    <AddSharpIcon />
                                                                </IconButton>
                                                            )}
                                                            {/* Delete Button */}
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleDeleteMedia(item, index, index >= mediaData.length);
                                                                }}
                                                                sx={{
                                                                    color: "black",
                                                                    backgroundColor: 'gray',
                                                                    '&:hover': { backgroundColor: 'red' },
                                                                }}
                                                                disabled={type === "text"}
                                                            >
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>

                                                            {/* Hidden File Input */}
                                                            <input
                                                                type="file"
                                                                id={`add-input-${index}`}
                                                                hidden
                                                                multiple
                                                                onChange={handleAddMedia}
                                                                disabled={type === "text"}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Slider>
                                        )}
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Box>
                    </Grid>)}
                    {/* )} */}

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Button variant="contained" color="primary" onClick={handleCreatePost}>
                                Save Post
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Post Created Successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default ManualPostCreation;
