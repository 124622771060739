import { Container, Box, Typography, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import PageHeader from '../../components/PageHeader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import makeRequest from '../../../src/makeRequest';
import CommentIcon from '@mui/icons-material/Comment';
import Insights from './Insights';
import { useDataContext } from '../../context/DataContext';
import ProductChannelStats from './ProductChannelStats';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductPostStats from './ProductPostStats';
import { CountCard } from './CountCard';
import GroupedCard from './GroupedCard';
import { useSearchParams } from 'react-router-dom';
import { removeNonValueFilters } from '../../helpers';
import LikesCommentChart from './LikesCommentChart';

function Dashboard() {
    const { products } = useDataContext();
    const [totalPosts, setTotalPosts] = useState(0);
    const [totalComments, setTotalComments] = useState(0);
    const [totalLikes, setTotalLikes] = useState(0);
    const [productStats, setProductStats] = useState([]);
    const [dynamicCardloading, setDynamicCardLoading] = useState(true);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [loadingComments, setLoadingComments] = useState(true);
    const [loadingLikes, setLoadingLikes] = useState(true);
    const [groupedCardLoading, setGroupedCardLoading] = useState(true);
    const [groupedCardData, setGroupedCardData] = useState([]);
    const [productCard, setProductCard] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const [selectedProduct, setSelectedProduct] = useState(existingParams?.prod || '');
    const [productFollowerCount, setProductFollowerCount] = useState([]);
    const selectFieldRef = useRef();


    const fetchAllPostCounts = async () => {
        try {
            const allPostCountRes = await makeRequest({
                url: `/api/post/all-post-counts`,
                method: 'GET',
            });
            if (allPostCountRes) {
                const publishedPostCounts = allPostCountRes.productChannelCounts.map(product => {
                    const publishedCount = product.channels.reduce((sum, channel) => {
                        return sum + (channel.postCounts.publishedEntries || 0);
                    }, 0);

                    return {
                        productId: product.productId,
                        productName: product.productName,
                        totalPosts: publishedCount
                    };
                });
                setTotalPosts(allPostCountRes?.groupedStatusCounts?.publishedEntries || 0);
                setProductStats(publishedPostCounts);
                setGroupedCardData(allPostCountRes)
            }
        } catch (error) {
            console.error('Failed to fetch all post count:', error);
            const productStats = products.map((product, index) => {
                return ({
                    productName: product.attributes.name,
                    totalPosts: 0,
                });
            });
            setProductStats(productStats);
        } finally {
            setLoadingPosts(false);
            setGroupedCardLoading(false);
            setDynamicCardLoading(false);
            setLoadingComments(false);
            setLoadingLikes(false);
        }
    };
    const fetchGroupedPostStats = async () => {
        try {
            const likeCommentCount = await makeRequest({
                url: `/api/posts/like-comment-counts?publicationState=preview`,
                method: 'GET',
            });

            if (likeCommentCount) {
                const likes = likeCommentCount.total_likes || 0;
                const comments = likeCommentCount.total_comments || 0;
                setTotalComments(comments);
                setTotalLikes(likes);
                setProductCard(likeCommentCount);
            } else {
                console.warn('No valid data in grouped post stats response.');
                setTotalComments(0);
                setTotalLikes(0);
            }
        } catch (error) {
            console.error('Error fetching grouped post stats:', error);
            setTotalComments(0);
            setTotalLikes(0);
        } finally {
            setLoadingComments(false);
            setLoadingLikes(false);
        }
    }
    const fetchAllFollowersCount = async () => {
        try {
            const followerCount = await makeRequest({
                url: `/api/product/follower-counts?publicationState=preview`,
                method: 'GET',
            });

            if (followerCount && Array.isArray(followerCount.products)) {
                followerCount.products.forEach(product => {
                    const productId = product.productId || 'Unknown';
                    const productName = product.productName || 'Unnamed Product';
                    const totalProductFollower = product.productFollowers || 0;
                    setProductFollowerCount(followerCount)
                });
            }
        } catch (error) {
        } finally {
        }
    };

    const fetchData = () =>{
        fetchAllPostCounts();
        fetchGroupedPostStats();
        fetchAllFollowersCount();
    }

    useEffect(() => {
       fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [1]);

    useEffect(() => {
        if (!existingParams?.prod) {
            setSelectedProduct('');
            // fetchData();
            return;
        }
        setSelectedProduct(existingParams?.prod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingParams?.prod]);

    const updateQueryParams = (params) => {
        const newParams = removeNonValueFilters({ ...existingParams, ...params });
        setQueryParams(newParams);
    };

    const handleProductChange = (event) => {
        let prodValue = event.target.value
        if (event.target.value == 'All') {
            prodValue = '';
        }
        setSelectedProduct(prodValue);
        updateQueryParams({ prod:prodValue });
    };

    const cardData = [
        {
            loading: loadingPosts,
            title: 'Total Posts',
            value: `${totalPosts || 0}`,
            icon: <FavoriteIcon color="primary" />,
        },
        {
            loading: loadingLikes,
            title: 'Total Likes',
            value: `${totalLikes || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
        {
            loading: loadingComments,
            title: 'Total Comments',
            value: `${totalComments || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
        // ...productStats.map((stat) => ({
        //     loading: dynamicCardloading,
        //     title: stat.productName,
        //     value: `${stat?.totalPosts || 0}`,
        //     icon: <PostAddIcon color="action" />,
        // })),
    ];

    const selectedProductCard = (selectedProduct) => {
        if (!selectedProduct || !groupedCardData?.productChannelCounts || !productCard?.products || !productFollowerCount?.products) return [];

        const product = groupedCardData.productChannelCounts.find(
            (item) => item.productId == selectedProduct
        );

        const productStats = productCard.products.find(
            (item) => item.product_id == selectedProduct
        );

        const followerStats = productFollowerCount.products.find(
            (item) => item.productId == selectedProduct
        );

        return [
            {
                title: 'Total Posts',
                value: `${product.total_product_published_posts || 0}`,
                icon: <FavoriteIcon color="primary" />,
                loading: dynamicCardloading,
            },
            {
                title: 'Total Likes',
                value: `${productStats.total_product_likes || 0}`,
                icon: <FavoriteIcon color="secondary" />,
                loading: loadingLikes,
            },
            {
                title: 'Total Comments',
                value: `${productStats.total_product_comments || 0}`,
                icon: <CommentIcon color="action" />,
                loading: loadingComments,
            },
            {
                title: 'Total Followers',
                value: `${followerStats.productFollowers || 0}`,
                icon: <CommentIcon color="action" />,
                loading: loadingComments,
            },
        ];
    };
    const visibleCards = cardData;
    const visibleProducts = groupedCardData?.productChannelCounts || [];

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1,
            }}
        >
            <Container maxWidth="xl">
                <PageHeader title="Dashboard" icon={<DashboardIcon />} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: selectedProduct ? 'space-between' : 'flex-end',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    {selectedProduct && (
                        <Typography variant="h6" >
                            {products?.find(
                                (product) => product.id == selectedProduct
                            )?.attributes?.name || ''}
                        </Typography>
                    )}

                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <TextField
                            inputRef={selectFieldRef}
                            select
                            name='productFilter'
                            value={selectedProduct}
                            onChange={handleProductChange}
                            label="Select Product"
                        >
                            <MenuItem value="All">
                                <em>All</em>
                            </MenuItem>
                            {products?.map((product) => (
                                <MenuItem
                                    key={product.id || product.attributes?.id}
                                    value={product.id || product.attributes?.id}
                                >
                                    {product.name || product.attributes?.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Box>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {selectedProduct
                        ? selectedProductCard(selectedProduct).map((card, index) => (
                            <Grid key={`selected-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCard
                                    sx={{ height: '100%' }}
                                    title={card.title}
                                    value={card.value}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))
                        : visibleCards.map((card, index) => (
                            <Grid key={`general-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCard
                                    sx={{ height: '100%' }}
                                    title={card.title}
                                    value={card.value}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))}
                </Grid>

                <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <GroupedCard
                            loading={groupedCardLoading}
                            title="Products"
                            subheader="All available products"
                            list={visibleProducts}
                            // totalLikes={totalLikes}
                            // totalComments={totalComments}
                            selectedProduct={selectedProduct}
                            setSelectedProduct={setSelectedProduct}
                            productFollowerCount ={productFollowerCount}
                        />
                    </Grid>
                </Box>

                {!selectedProduct && (<Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <Insights />
                    </Grid>
                </Box>)}
                {selectedProduct && <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <ProductChannelStats productId={selectedProduct} />
                    </Grid>
                </Box>}
                {selectedProduct && <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <ProductPostStats productId={selectedProduct}/>
                    </Grid>
                </Box>}
        
                {selectedProduct && <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <LikesCommentChart productId={selectedProduct} />
                    </Grid>
                </Box>}

            </Container>
        </Box>
    );
}

export default Dashboard;
