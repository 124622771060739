import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getStatusBgColor, removeNonValueFilters } from "../helpers";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAuthContext } from "../context/AuthContext";
import CustomToolbar from "./CustomToolbar/toolbar";
import makeRequest from "../makeRequest";
import { Box } from "@mui/material";
import MUIModal from "./MUIModal";
import PostStatsChart from "./PostStatsChart";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDataContext } from "../context/DataContext";
import Loader from "./Loader";
import PostInsights from "../pages/Schedule/PostInsights";
import PostLikeCommentChart from "./PostLikeCommentChart";

const localizer = momentLocalizer(moment);

function CalendarComponent() {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { postStatusList } = useDataContext();
    const [eventsByDate, setEventsByDate] = useState({});
    const [loading, setLoading] = useState(true);
    const [queryParams, setQueryParams] = useSearchParams();
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const existingParams = Object.fromEntries([...queryParams]);
    const cview = existingParams?.cview || 'month';
    const date = existingParams?.date || new Date().toISOString().split('T')[0];
    const urlStatus = existingParams?.status || '';
    const [currentDate, setCurrentDate] = useState(date);

    const fetchEventData = async () => {
        let page = 1;
        const pageSize = 100;
        let allEvents = [];
        setLoading(true);

        try {
            if (!user || !user.username) {
                console.error("User not found or not logged in.");
                setLoading(false);
                return;
            }

            const params = {
                filters: {
                    scheduled_by: { username: { $eq: user.username } },
                },
                pagination: { pageSize }
            };

            if (urlStatus) {
                params.filters.status = { $eq: urlStatus.toLowerCase() };
            }

            let keepFetching = true;
            while (keepFetching) {
                params.pagination.page = page;

                const response = await makeRequest({
                    method: 'GET',
                    url: `/api/posts?publicationState=preview`,
                    params,
                });
                const posts = response.data;
                allEvents = [...allEvents, ...posts];
                keepFetching = posts.length === pageSize;
                page += 1;
            }

            const eventsData = allEvents.map((post) => {
                const attributes = post.attributes;
                const status = attributes.status.toLowerCase();
                const content = attributes.content || "Untitled Event";
                const date = status === "published"
                    ? moment(attributes.tm_posted).format("YYYY-MM-DD")
                    : moment(attributes.tm_scheduled).format("YYYY-MM-DD");
                const time = status === "published"
                    ? moment(attributes.tm_posted).format("HH:mm")
                    : moment(attributes.tm_scheduled).format("HH:mm");

                return { date, content, time, status, postInfo: post };
            });

            eventsData.sort((a, b) => moment(a.date + 'T' + a.time) - moment(b.date + 'T' + b.time));
            const groupedEvents = eventsData.reduce((acc, event) => {
                const date = event.date;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(event);
                return acc;
            }, {});

            setEventsByDate(groupedEvents);
        } catch (error) {
            console.error("Error fetching events:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user && user.id) {
            fetchEventData();
        }
    }, [user, urlStatus]);

    const handleSelectEvent = (event) => {
        const eventMonth = moment(event.start).month();
        const currentMonth = moment(currentDate).month();
        if (eventMonth !== currentMonth) {
            return;
        }
        const selectedDate = moment(event.start).toDate();
        const evtDate = moment(event.start).format("YYYY-MM-DD");
        if (cview === 'month') {
            if (event.postInfo?.attributes?.status.toLowerCase() === 'published') {
                setSelectedEvent(event.postInfo);
            } else {
                navigate(`/schedule/edit-post/${event.postInfo.id}`);
            }
        } else if (cview === 'day') {
            setCurrentDate(selectedDate);
            updateQueryParams({ cview: 'day', date: evtDate });
            if (event.postInfo?.attributes?.status.toLowerCase() === 'published') {
                setSelectedEvent(event.postInfo);
            } else if (event.postInfo?.attributes?.status.toLowerCase() !== 'published') {
                navigate(`/schedule/edit-post/${event.postInfo.id}`)
            }
        } else if (cview === 'week') {
            if (event.postInfo?.attributes?.status.toLowerCase() === 'published') {
                setSelectedEvent(event.postInfo);
            } else {
                navigate(`/schedule/edit-post/${event.postInfo.id}`);
            }
        }
    };

    const eventStyleGetter = (event) => {
        const eventDate = moment(event.start);
        const currentMonth = moment(currentDate).month();
        const eventMonth = eventDate.month();
        const isInactive = currentMonth !== eventMonth;
        const backgroundColor = isInactive ? "#d3d3d3" : getStatusBgColor(event?.status?.toLowerCase());
        const textColor = isInactive ? "#a9a9a9" : "white";

        return {
            style: {
                backgroundColor,
                borderRadius: "0px",
                opacity: isInactive ? 0.8 : 1,
                color: textColor,
                border: "0px",
                display: 'block',
                pointerEvents: isInactive ? 'none' : 'auto',
            },
        };
    };

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleClose = () => {
        setSelectedEvent(undefined);
    }

    const handleNavigate = (newDate) => {
        setCurrentDate(newDate);
        updateQueryParams({ date: moment(newDate).format('YYYY-MM-DD') });
    };

    const handleDrillDown = (date) => {
        const currentMonth = moment(currentDate).month();
        const drillDownMonth = moment(date).month();
        if (drillDownMonth !== currentMonth) {
            return;
        }
        setCurrentDate(date);
        updateQueryParams({ cview: "day", date: moment(date).format("YYYY-MM-DD") });
    };

    if (loading) {
        return <Loader />
    }

    const events = Object.keys(eventsByDate).reduce((acc, date) => {
        return acc.concat(eventsByDate[date].map(event => {
            return ({
                start: moment(date + 'T' + event.time).toDate(),
                end: moment(date + 'T' + event.time).add(1, 'hour').toDate(),
                title: event.content || "Untitled Event",
                status: event.status || 'Unknown',
                postInfo: event.postInfo || {}
            })
        }));
    }, []);

    return (
        <Box component='div' m={2}>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <Calendar
                    dayLayoutAlgorithm='no-overlap'
                    localizer={localizer}
                    defaultDate={new Date()}
                    date={currentDate}
                    view={cview || 'month'}
                    views={["month", "week", "day"]}
                    selectable
                    components={{
                        toolbar: (props) => (
                            <CustomToolbar
                                {...props}
                                view={cview}
                                statusOptions={postStatusList}
                                filterStatus={urlStatus}
                                setFilterStatus={(statusStr) => { updateQueryParams({ status: statusStr }) }}
                            />
                        ),
                    }}
                    events={events.length > 0 ? events : []}
                    style={{ height: "calc(100vh - 100px)" }}
                    onView={(viewStr) => {
                        updateQueryParams({ cview: viewStr });
                    }}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={(slotInfo) => {
                        const selectedDate = moment(slotInfo.start).toDate();
                        const currentMonth = moment(currentDate).month();
                        const slotMonth = moment(slotInfo.start).month();
                        if (slotMonth !== currentMonth) {
                            return;
                        }
                        const evtDate = moment(slotInfo.start).format("YYYY-MM-DD");
                        setCurrentDate(selectedDate);
                        updateQueryParams({ cview: "day", date: evtDate });
                    }}
                    onNavigate={handleNavigate}
                    eventPropGetter={eventStyleGetter}
                    titleAccessor="title"
                    onDrillDown={handleDrillDown}

                />
            )}

            {selectedEvent && <MUIModal
                maxWidth='md'
                title={`Post Statistics - Post Id: ${selectedEvent.id}`}
                open
                handleClose={handleClose}
            >
                {/* <PostStatsChart postId={selectedEvent.id} postUrl={selectedEvent.attributes.post_url}/> */}
                <PostLikeCommentChart postId={selectedEvent.id} postUrl={selectedEvent.attributes.post_url}/>
            </MUIModal>}
            {/* {selectedEvent && <MUIModal
                maxWidth='md'
                title={`Meta Insights - Post Id: ${selectedEvent.id}`}
                open
                handleClose={handleClose}
            >
                <PostInsights postId={selectedEvent.id} postUrl={selectedEvent.attributes.post_url}/>
            </MUIModal>} */}

        </Box>
    );
}

export default CalendarComponent;