import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFound from "../pages/NotFound";
import PostStatsChart from "../components/PostStatsChart";
import ViewStatsTable from "../pages/ViewStats";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import Dashboard from "../pages/Dashboard";
import Schedule from "../pages/Schedule";
import EditPost from "../pages/Schedule/EditPost";
import CreatePost from "../pages/Schedule/CreatePost";
import Notifications from "../pages/Notifications";
import Settings from "../pages/Settings";
// import ManualPostCreation from "../pages/Schedule/ManualPostCreation";
export const commonRouteItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Schedule",
    path: "/schedule",
    icon: <CalendarMonthIcon />,
  },
  {
    title: "Notifications",
    path: "/notifications",
    icon: <NotificationsIcon />,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <SettingsIcon />,
  },
];

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
      {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/view-post-stats/:postId" element={<PostStatsChart />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/schedule/edit-post/:id" element={<EditPost />} />
        <Route path="/schedule/create-post" element={<CreatePost />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/manual-post" element={<ManualPostCreation />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
