import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getStatusBgColor } from '../../helpers';
import Loader from '../../components/Loader';
import makeRequest from '../../makeRequest';
import { Unstable_Grid2 as Grid } from '@mui/material'
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
export default function GroupedCard({ loading, title, subheader, list, selectedProduct, setSelectedProduct, productFollowerCount, ...other }) {

  const [productData, setProductData] = useState([]);
  useEffect(() => {
    async function fetchProductPostStats() {
      try {
        const response = await makeRequest({
          url: `/api/posts/like-comment-counts?publicationState=preview`,
          method: 'GET',
        });
        const { products } = response;

        const updatedList = list.map(product => {
          const apiProduct = products.find(apiProd => apiProd.product_id === product.productId);
          return {
            ...product,
            totalLikes: apiProduct ? apiProduct.total_product_likes : 0,
            totalComments: apiProduct ? apiProduct.total_product_comments : 0,
            channels: product.channels.map(channel => {
              const apiChannel = apiProduct?.channels?.find(apiChan => apiChan.channel_id === channel.channel_id);
              // if (!apiChannel) {
              //   console.warn(`Channel ID ${channel.channel_id} not found for Product ID ${product.productId}.`);
              // }
              return {
                ...channel,
                totalChannelLikes: apiChannel?.total_channel_likes || 0,
                totalChannelComments: apiChannel ? apiChannel.total_channel_comments : 0,
              };
            }),
          };
        });
        setProductData(updatedList);

      } catch (error) {
        console.error('Error fetching grouped post stats:', error);
      }
    }
    fetchProductPostStats();
  }, [list]);

  const filteredProducts = productData.filter(product => product.productId === Number(selectedProduct));
  const followerData = productFollowerCount;

  const noData = (<Box component="div" display="flex" justifyContent="center" sx={{ textAlign: 'center' }} width="100%" my={4}>
      <Typography variant="subtitle2">No data available</Typography>
    </Box>
  )



  return (
    <div>
      <Card {...other}>
        <CardHeader
          title={selectedProduct ? "Channel wise Stats" : title}
          subheader={selectedProduct ? "" : subheader}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        />
        <Box>
          {loading && <Loader />}
          {selectedProduct ? (
            filteredProducts.map(product => (
              <div key={product.productId}>
                {/* <CardHeader
                  title={
                    <Box display="flex" alignItems="center" gap={2}>
                      {product.productName}
                      <Typography variant="body2" sx={{ color: getStatusBgColor('published') }}>
                        Total Posts: {product.channels.reduce((sum, channel) => sum + (channel.postCounts?.publishedEntries || 0), 0)}
                      </Typography>
                      <Typography variant="body2">Total Likes: {product.totalLikes}</Typography>
                      <Typography variant="body2">Total Comments: {product.totalComments}</Typography>
                    </Box>
                  }
                /> */}
                { product.channels.length === 0 && noData }
                {product.channels.map(channel => (
                  <Box
                    key={channel.channel_id}
                    sx={{
                      // mt: 2,
                      p: 1,
                      pl: 3,
                      // border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      // textAlign: 'center',
                    }}
                  >
                    <Box sx={{ width: '100%', py: 2 }}>
                      <Stack
                        direction={{ sm: 'row' }} alignItems="center" justifyContent="flex-start">

                        <Box component='div' mr={1}>
                          {channel.channel_type === 'facebook_page' && <FacebookIcon />}
                          {channel.channel_type === 'instagram_page' && <InstagramIcon />}
                        </Box>

                        <Typography variant="subtitle2" component="span">
                          {channel.display_name}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={6} alignItems="center" justifyContent='flex-start' sx={{ mt: 1, flexWrap: 'wrap' }}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={2}
                          // spacing={{ xs: 1, sm: 2, md: 4 }}
                          // useFlexGap
                          sx={{ flexWrap: 'wrap', 
                            textAlign: { xs: 'left', sm: 'center' },
                          }}>
                          {/* <Typography variant="body2">
                            Total Followers:{' '}
                            {followerData.products
                              .find(prod => prod.productId === product.productId)
                              ?.channels.find(chan => chan.channelId === channel.channel_id)
                              ?.channelFollowers || 0}
                          </Typography>                           */}
                          <Typography variant="body2">Total Posts: {channel.totalPosts}</Typography>
                          <Typography variant="body2">Total Likes: {channel.totalChannelLikes}</Typography>
                          <Typography variant="body2">Total Comments: {channel.totalChannelComments}</Typography>
                        </Stack>

                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={2}
                          // spacing={{ xs: 1, sm: 2, md: 4 }}
                          // useFlexGap
                          sx={{ flexWrap: 'wrap' }}>
                          <Typography variant="body2">
                            <span style={{ color: getStatusBgColor('draft') }}>Draft: {channel.postCounts?.draftEntries || 0}</span>
                          </Typography>
                          <Typography variant="body2">
                            <span style={{ color: getStatusBgColor('waiting') }}>Waiting: {channel.postCounts?.waitingEntries || 0}</span>
                          </Typography>
                          <Typography variant="body2">
                            <span style={{ color: getStatusBgColor('published') }}>Published: {channel.postCounts?.publishedEntries || 0}</span>
                          </Typography>
                          <Typography variant="body2">
                            <span style={{ color: getStatusBgColor('error') }}>Error: {channel.postCounts?.errorEntries || 0}</span>
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </div>
            ))
          ) : (
            productData.map(product => (
              <Fragment key={product.productId}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${product.productId}-content`}
                    id={`panel-${product.productId}-header`}
                    sx={{px: 3}}
                  >
                    <Stack
                      direction={{sm: 'row' }}
                      spacing={2} alignItems="center">
                      <Typography variant="title">
                        {product.productName}
                      </Typography>

                      <Stack
                        direction={{ xs: 'row', sm: 'row' }}
                        spacing={2} alignItems="center"
                        justifyContent="flex-start"
                        sx={{ flexWrap: 'wrap' }}>
                        <Typography variant="body2">Total Followers: 0</Typography>
                        <Typography variant="body2">Total Posts: {product.channels.reduce((sum, channel) => sum + (channel.postCounts?.publishedEntries || 0), 0)}</Typography>
                        <Typography variant="body2">Total Likes: {product.totalLikes}</Typography>
                        <Typography variant="body2">Total Comments: {product.totalComments}</Typography>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{px: 3}}>
                  {loading && <Loader />}
                  { product.channels.length === 0 && noData }
                    {product.channels.map((channel,idx) => (
                      <Box key={`prod-${product.productId}-channel-${idx}`} sx={{ width: '100%', py: 2 }}>
                        <Stack
                          direction={{ sm: 'row' }} alignItems="center" justifyContent="flex-start">
                          <Box component='div' mr={1}>
                            {channel.channel_type === 'facebook_page' && <FacebookIcon />}
                            {channel.channel_type === 'instagram_page' && <InstagramIcon />}
                          </Box>
                          <Typography variant="subtitle2" component="span">
                            {channel.display_name}
                          </Typography>
                        </Stack>

                        <Stack direction="row" spacing={6} alignItems="center" justifyContent='flex-start' sx={{ mt: 1, flexWrap: 'wrap' }}>
                          {/* <Box sx={{ width: '50px' }} /> */}
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            sx={{ flexWrap: 'wrap', 
                              textAlign: { xs: 'left', sm: 'center' },
                            }}>
                            {/* <Typography variant="body2">Total Followers:10</Typography> */}
                            <Typography variant="body2">Total Posts: {channel.totalPosts}</Typography>
                            <Typography variant="body2">Total Likes: {channel.totalChannelLikes}</Typography>
                            <Typography variant="body2">Total Comments: {channel.totalChannelComments}</Typography>
                          </Stack>

                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            // useFlexGap
                            sx={{ flexWrap: 'wrap' }}>
                            <Typography variant="body2">
                              <span style={{ color: getStatusBgColor('draft') }}>Draft: {channel.postCounts?.draftEntries || 0}</span>
                            </Typography>
                            <Typography variant="body2">
                              <span style={{ color: getStatusBgColor('waiting') }}>Waiting: {channel.postCounts?.waitingEntries || 0}</span>
                            </Typography>
                            <Typography variant="body2">
                              <span style={{ color: getStatusBgColor('published') }}>Published: {channel.postCounts?.publishedEntries || 0}</span>
                            </Typography>
                            <Typography variant="body2">
                              <span style={{ color: getStatusBgColor('error') }}>Error: {channel.postCounts?.errorEntries || 0}</span>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            ))
          )}
        </Box>
      </Card>
    </div>
  );
}

GroupedCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  totalLikes: PropTypes.number,
  totalComments: PropTypes.number,
};
