import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import makeRequest from "../../makeRequest";
import { Button, Grid, Box, IconButton, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { useDataContext } from '../../context/DataContext';
import MUILoader from "../../components/MUILoader";
import Typography from '@mui/material/Typography';
import { Snackbar, Alert } from '@mui/material';
import PageHeader from "../../components/PageHeader";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Loader from "../../components/Loader";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const NextArrow = ({ onClick, disabled }) => {
    return (
        !disabled && <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'absolute',
                top: '50%',
                right: '-6px',
                transform: 'translateY(-50%)',
                color: '#FFF',
                backgroundColor: 'rgba(0,0,0,0.5) !important',
                zIndex: 1,
            }}
        >
            <ChevronRightIcon />
        </IconButton>
    );
};

const PrevArrow = ({ onClick, disabled }) => {
    return (
        !disabled && <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '-6px',
                transform: 'translateY(-50%)',
                color: '#FFF',
                backgroundColor: 'rgba(0,0,0,0.5) !important',
                zIndex: 1,
            }}
        >
            <ChevronLeftIcon />
        </IconButton>
    );
};


function EditPost({ pId, cardType, triggerClose }) {
    const { id } = useParams();
    const postId = id || pId;
    const { channels, postTypes, postStatusList } = useDataContext();
    const slider = useRef();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [channel, setChannel] = useState('');
    const [type, setType] = useState("multi-images");
    const [status, setStatus] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [postMediaData, setPostMediaData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const [jsData, setJsData] = useState(null);
    const [scheduledBy, setScheduledBy] = useState('');
    const [userArray, setUserArray] = useState([]);
    const [content, setContent] = useState("");
    const [imagePrompt, setImagePrompt] = useState("");
    const [scheduledTime, setScheduledTime] = useState(null);

    const [originalChannel, setOriginalChannel] = useState(null);
    const [originalScheduledBy, setOriginalScheduledBy] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        if (currentSlide < [...mediaData, ...newMedia].length - 1) {
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    };

    const afterSlideChange = (slide) => {
        setCurrentSlide(slide);
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !(type === 'single-image-post' || type === 'single-video-post'),
        nextArrow: <NextArrow onClick={handleNext} disabled={currentSlide === [...mediaData, ...newMedia].length - 1} />,
        prevArrow: <PrevArrow onClick={handlePrev} disabled={currentSlide === 0} />,
        // responsive: [
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1
        //         }
        //     }
        // ],
        className: 'file-select-slider'
    };

    const fetchPostDetails = async () => {
        setLoading(true);
        try {
            const [respData, userResponse] = await Promise.all([
                makeRequest({ method: 'GET', url: `/api/posts/${postId}?populate=*&publicationState=preview&populate=channel.product&populate=media&populate=scheduled_by` }),
                makeRequest({ method: 'GET', url: '/api/users' })
            ]);
            setData(respData);
            // console.log("respData", respData.data);

            const channelData = respData.data.attributes.channel?.data?.attributes;
            if (channelData) {
                if (channelData?.product?.data) {
                    const fProd = channelData.product.data;
                    setSelectedProduct(fProd)
                }
                const defaultChannel = {
                    id: respData.data.attributes.channel.data.id,
                    label: `${channelData.display_name} - ${channelData.channel_type}`,
                };
                setChannel(defaultChannel.id); // Set default selected value
                // setChannelArray(prev => [...prev, defaultChannel]);
                // console.log('defaultChannel: ', defaultChannel);
                setOriginalChannel(defaultChannel.id);
            }
            const pMedia = respData.data.attributes.media?.data?.map(item => ({
                ...item.attributes,
                id: item.id,
            })) || [];
            setPostMediaData(pMedia);
            const mediaUrls = respData.data.attributes.media?.data?.map(item => ({
                img: item.attributes.url
            })) || [];
            const uniqueMediaUrls = Array.from(new Set(mediaUrls.map(item => item.img)))
                .map(url => ({ img: url }));

            setMediaData(uniqueMediaUrls);

            const userId = respData.data.attributes.scheduled_by?.data?.id;
            if (userId) {
                const user = userResponse.find((user) => user.id === userId);
                if (user) {
                    setScheduledBy(user.id);
                }
                setOriginalScheduledBy(user.id);
            }

            setType(respData.data.attributes.type || "");

            setStatus(respData.data.attributes.status || "");

            // setScheduledBy(respData.data.attributes.scheduled_by.data.attributes.username || "");

            setContent(respData.data.attributes.content || "");

            const jsonData = respData.data.attributes.jsonData;
            if (jsonData) {
                if (jsonData?.image_prompt) {
                    setImagePrompt(jsonData?.image_prompt);
                }
                setJsData(jsonData);
            }
            // setScheduledTime(dayjs(respData.data.attributes.scheduled_time || dayjs()));
            const scheduledTime = respData.data.attributes.tm_scheduled;
            if (scheduledTime) {
                setScheduledTime(dayjs(scheduledTime));
            }

            // console.log('user response: ', userResponse);
            const allUsers = userResponse.map(user => ({
                id: user.id,
                label: `${user.username}`,
            }));
            // console.log('allUsers: ', allUsers);
            setUserArray(allUsers);

        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!postId) return;
        fetchPostDetails();
    }, [postId]);


    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleScheduledByChange = (event) => {
        setScheduledBy(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };
    const handleImagePromptChange = (event) => {
        setImagePrompt(event.target.value);
    };


    /*
        const validateMediaFiles = (mData,) =>{
        let isValid = false;
    
        for (const mediaItem of mData) {
            const isImage = mediaItem.type.startsWith('image/');
            const isVideo = mediaItem.type.startsWith('video/');;
            console.log('mediaItem: ', mediaItem.type);
        }
        return isValid;
        }
    */
    const handleSave = async () => {
        let updatedMedia = [...postMediaData];
        try {
            // Validate Media Extensions
            if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
                // Validate only images are uploaded
                const invalidMedia = newMedia.filter(
                    (media) => media.file && media.file.type.startsWith('video/')
                );

                if (invalidMedia.length > 0) {
                    setIsLoading(false);
                    alert("Video files are not allowed. Please upload only images!");
                    return;
                }
            } else if (['single-video-post', 'multi-videos', 'story-video'].includes(type)) {
                // Validate only videos are uploaded
                const invalidMedia = newMedia.filter(
                    (media) => media.file && !media.file.type.startsWith('video/')
                );

                if (invalidMedia.length > 0) {
                    setIsLoading(false);
                    alert("Only video files are allowed. Please upload valid videos!");
                    return;
                }
            }
            // -------- Handle Media Upload --------
            if (newMedia.length > 0) {

                /*
                const isValidMediaFiles = validateMediaFiles(newMedia.map(mItem=>(mItem.file)));
                if (type !=='text' && !isValidMediaFiles) {
                    alert("No valid media files selected.");
                    return;
                }
                */

                const formData = new FormData();
                for (const mediaItem of newMedia) {
                    formData.append('files', mediaItem?.file);
                }

                const uploadResponse = await makeRequest({
                    method: "POST",
                    url: "/api/upload",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (!uploadResponse) {
                    throw new Error("Failed to upload media.");
                }

                updatedMedia = [...updatedMedia, ...uploadResponse];

            }

            // -------- Update Post Data --------
            const selectedChannel = channels.find((ch) => ch.id === channel);
            const channelDisplayName = selectedChannel ? selectedChannel.label : channel;

            const updatedScheduledTime = scheduledTime.toISOString();

            const selectedUser = userArray.find((user) => user.id === scheduledBy);
            const scheduledByUsername = selectedUser ? selectedUser.label : scheduledBy;

            //-------Content update----
            const updatedContent = content;
            // console.log('content: ', content);

            // -----------Type update
            const updatedType = type;
            // console.log('type: ', type);

            // ----------Status Update-----
            const updatedStatus = status;
            // console.log('status', status);

            const updatedScheduledBy = [];
            if (scheduledBy !== originalScheduledBy) {
                updatedScheduledBy.push({
                    id: scheduledBy,
                    position: { end: true },
                });
            }

            let updatedJsonData = null;
            if (jsData && imagePrompt) {
                updatedJsonData = {
                    ...jsData,
                    image_prompt: imagePrompt
                }
            } else if (!jsData && imagePrompt) {
                updatedJsonData = {
                    image_prompt: imagePrompt
                }
            } else {
                updatedJsonData = {
                    image_prompt: imagePrompt
                }
            }

            const dataToUpdate = {
                channel: {
                    disconnect: [],
                    connect: channel !== originalChannel
                        ? [{ id: channel, position: { end: true } }]
                        : [],
                },
                tm_scheduled: updatedScheduledTime,
                content: updatedContent,
                type: updatedType,
                status: updatedStatus,
                scheduled_by: {
                    disconnect: [],
                    connect: updatedScheduledBy,
                },
                media: updatedMedia,
                jsonData: updatedJsonData
            };
            if (type !== 'text' && updatedMedia.length === 0) {
                alert("No valid media files selected.");
                return;
            }
            setIsLoading(true);
            const response = await makeRequest({
                method: "PUT",
                url: `/api/posts/${postId}`,
                data: { data: dataToUpdate },
            });
            if (response?.data) {
                setOpenSnackbar(true);
                if (cardType) {
                    triggerClose();
                }
            }
            navigate('/schedule?view=calendar');
        } catch (error) {
            console.error("Error saving post:", error);
            setOpenSnackbar(false);
        }
        setIsLoading(false);
    };

    const handleAddMedia = (event) => {
        if (!event.target.files || event.target.files.length === 0) {
            // alert("No files selected.");
            return;
        }

        const files = Array.from(event.target.files).filter((file) => {
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type === 'video/mp4';

            if (type === 'single-video-post' || type === 'multi-video-post') {
                if (!isVideo) {
                    alert("Only MP4 videos are allowed.");
                    return false;
                }
            } else if (type === 'single-image-post' || type === 'multi-posts') {
                if (!isImage) {
                    alert("Only images are allowed.");
                    return false;
                }
            }

            return true;
        });

        if (files.length === 0) {
            // alert("No valid files selected.");
            return;
        }

        try {
            const formattedFiles = files.map((file) => ({
                file,
                img: URL.createObjectURL(file), // Create preview URL
            }));

            setNewMedia((prevMedia) => [...prevMedia, ...formattedFiles]);
        } catch (error) {
            console.error("Error creating object URL:", error);
        }
    };

    const handleDeleteMedia = (dItem, index, isNew) => {
        if (isNew) {
            setNewMedia(prev => prev.filter((fItem) => fItem.img !== dItem.img));
        } else {
            const fData = postMediaData.find(item => item.url === dItem.img)
            if (fData) {
                setPostMediaData(prev => prev.filter(fItem => fItem.id !== fData.id))
            }
            setMediaData(prev => prev.filter((_, i) => i !== index));
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);  // Close Snackbar manually if required
    };

    const handleGenerateImage = async () => {
        try {
            if (!selectedProduct) {
                console.log('No selectedProduct: ');
                return
            }
            setIsLoading(true);
            const response = await makeRequest({
                method: "POST",
                url: `/api/chatgpt/generate-image`,
                data: {
                    postId,
                    product: selectedProduct?.id,
                    imagePrompt
                },
            });
            console.log('Image Generate response: ', response);
            if (response?.data) {
                fetchPostDetails();
            }
        } catch (error) {
            console.error("Error saving post:", error);
        }
        setIsLoading(false);
    }

    if (loading) {
        return <Loader />; // Render a loading spinner or message
    }

    const channelOptions = channels.map(channelItem => {
        return ({
            id: channelItem.id,
            label: `${channelItem.attributes.display_name} - ${channelItem.attributes.channel_type}`,
        })
    });


    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: pId ? 1 : 8,
                px: 1
            }}>

            <Container maxWidth="xl">
                {!pId && <PageHeader title="Edit Post" icon={<EditCalendarIcon />} />}
                <Box sx={{
                    mt: pId ? 1 : 4,
                    flexGrow: 1
                }}>

                    {isLoading && <MUILoader open />}
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                                <TextField
                                    select
                                    fullWidth
                                    value={channel}
                                    label="Channel"
                                    onChange={handleChannelChange}
                                >
                                    {channelOptions.map((channel) => (
                                        <MenuItem key={channel.id} value={channel.id}>
                                            {channel.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    value={type}
                                    label="Type"
                                    fullWidth
                                    onChange={handleTypeChange}
                                >
                                    {postTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Scheduled Time"
                                        value={scheduledTime}
                                        onChange={(newValue) => setScheduledTime(newValue)}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                                {data && (
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Content"
                                        multiline
                                        rows={6.7}
                                        value={content}
                                        onChange={handleContentChange}
                                        fullWidth
                                    />
                                )}
                                <TextField
                                    select
                                    value={status}
                                    label="Status"
                                    fullWidth
                                    onChange={handleStatusChange}
                                >
                                    {postStatusList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Box>
                                    <TextField
                                        select
                                        value={scheduledBy}
                                        label="Scheduled By"
                                        fullWidth
                                        onChange={handleScheduledByChange}
                                    >
                                        {userArray.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                            {data && (
                                    <>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Image Prompt"
                                            multiline
                                            rows={4}
                                            value={imagePrompt}
                                            onChange={handleImagePromptChange}
                                            fullWidth
                                        />
                                        {data && (
                                            <Box
                                                mt={1}
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="flex-end">
                                                <Button  onClick={handleGenerateImage} disabled={!imagePrompt}>
                                                    Generate Image
                                                </Button>
                                            </Box>
                                        )}
                                    </>
                                )}
                                {type !== 'text' && (
                                    <Box
                                        p={1}
                                        sx={{
                                            border: '1px solid lightgray',
                                            borderRadius: '8px',
                                        }}>
                                        <Typography variant="body2" color="textSecondary" mb={0.4}>
                                            Media
                                        </Typography>
                                        <Box alignItems="center">
                                            {type === "text" ? (
                                                <Typography variant="subtitle1" color="textSecondary">
                                                </Typography>
                                            ) : mediaData.length === 0 && newMedia.length === 0 ? (
                                                // If no media is present
                                                <Box
                                                    sx={{
                                                        // height: '150px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#f9f9f9',
                                                        borderRadius: '8px',
                                                        '&:hover .hover-actions': {
                                                            opacity: 0.9,
                                                        },
                                                    }}
                                                >
                                                    <Box
                                                        className="hover-actions"
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sx={{
                                                            opacity: 1,
                                                            transition: 'opacity 0.7s ease-in-out',
                                                            borderRadius: '8px',
                                                            height: '314px'
                                                        }}
                                                    >
                                                        {/* ---------Add Media--------- */}
                                                        {!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0 ? (
                                                            <IconButton
                                                                onClick={() => document.querySelector('#add-input-default').click()}
                                                                sx={{
                                                                    color: 'black',
                                                                    backgroundColor: 'gray',
                                                                    '&:hover': { backgroundColor: 'white' },
                                                                }}
                                                            >
                                                                <AddSharpIcon />
                                                            </IconButton>
                                                        ) : null}
                                                        <input
                                                            type="file"
                                                            id="add-input-default"
                                                            hidden
                                                            multiple={type !== 'single-video-post'}
                                                            accept={type === 'multi video post' || type === 'single-video-post' ? 'video/mp4' : '*/*'}
                                                            onChange={handleAddMedia}
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box className="file-select-slider-block">
                                                    <Slider {...settings} afterChange={(e) => afterSlideChange(e)} ref={slider}>
                                                        {[...mediaData, ...newMedia].map((item, index) => (
                                                            <Box
                                                                component="div"
                                                                key={index}
                                                                position="relative"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    position: 'relative',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: 'transparent',
                                                                    '&:hover .hover-actions': {
                                                                        opacity: 0.9,
                                                                    },
                                                                }}
                                                            >
                                                                {item.file?.type.startsWith('video/') || item.img?.endsWith('.mp4') ? (
                                                                    <video
                                                                        controls
                                                                        style={{
                                                                            margin: "0 auto",
                                                                            objectFit: 'cover',
                                                                        }}
                                                                    >
                                                                        <source src={item.img} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : (
                                                                    <img
                                                                        src={item.img}
                                                                        alt={`media-${index}`}
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            margin: "0 auto",
                                                                            objectFit: 'cover',
                                                                            borderRadius: '8px',
                                                                        }}
                                                                    />
                                                                )}

                                                                {/* -------Hover Actions------- */}
                                                                <Box
                                                                    className="hover-actions"
                                                                    position="absolute"
                                                                    // top={0}
                                                                    left={0}
                                                                    right={0}
                                                                    bottom={0}
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    bgcolor="rgba(0, 0, 0, 0.8)"
                                                                    color="#fff"
                                                                    sx={{
                                                                        opacity: 0,
                                                                        transition: 'opacity 0.7s ease-in-out',
                                                                        borderRadius: '4px'
                                                                    }}
                                                                >
                                                                    {/* -------Add Media ------ */}
                                                                    {(!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0) && (
                                                                        <IconButton
                                                                            onClick={() => document.querySelector(`#add-input-${index}`).click()}
                                                                            sx={{
                                                                                marginRight: '8px',
                                                                                color: 'black',
                                                                                backgroundColor: 'gray',
                                                                                '&:hover': { backgroundColor: 'white' },
                                                                            }}
                                                                            disabled={type === 'single-image-post' || type === 'single-video-post' ? (mediaData.length + newMedia.length) === 1 : false}
                                                                        >
                                                                            <AddSharpIcon />
                                                                        </IconButton>
                                                                    )}
                                                                    {/* Delete Button */}
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleDeleteMedia(item, index, index >= mediaData.length);
                                                                        }}
                                                                        sx={{
                                                                            color: "black",
                                                                            backgroundColor: 'gray',
                                                                            '&:hover': { backgroundColor: 'red' },
                                                                        }}
                                                                        disabled={type === "text"}
                                                                    >
                                                                        <DeleteOutlineOutlinedIcon />
                                                                    </IconButton>

                                                                    {/* Hidden File Input */}
                                                                    <input
                                                                        type="file"
                                                                        id={`add-input-${index}`}
                                                                        hidden
                                                                        multiple
                                                                        onChange={handleAddMedia}
                                                                        disabled={type === "text"}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Slider>
                                                </Box>
                                            )}
                                        </Box>

                                    </Box>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-end">
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Automatically close after 3 seconds
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Successfully Saved!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default EditPost; 
