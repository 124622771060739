import React, { useMemo, useEffect, useState } from 'react';
import makeRequest from '../makeRequest';
import * as moment from 'moment';
import Box from '@mui/material/Box';
import FilterButtonGroup from './FilterButtonGroup';
import { Button, Typography, Tooltip as MUITooltip, CircularProgress, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Chart from './Chart/Chart';
import useChart from '../hooks/useChart';
import { chartDefaultDateRange } from '../helpers';

const PostLikeCommentChart = ({ postId, postUrl }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period, setPeriod] = useState('today');
  const [chartData, setChartData] = useState({});

  const handleDurationSelect = (duration) => {
    if (typeof duration === 'object') {
      setPeriod(null);
      setStartDate(duration.startDate);
      setEndDate(duration.endDate);
    } else {
      const periodState = {
        'Today': 'today',
        'last 24 hours': 'last24hours',
        'last week': 'inWeek',
        'last month': 'inMonth',
      };
      setStartDate(null);
      setEndDate(null);
      setPeriod(periodState[duration]);
    }
  };

  const fetchLikesAndCommentsOverTime = async (pId) => {
    try {
      setLoading(true);
      const chartDataResp = await makeRequest({
        url: `/api/posts/likes-comments-chart-data?postId=${pId}&period=${period}`,
        method: 'GET',
      });

      const chartData = chartDataResp?.data?.chartData;
      if (chartData?.series.some(series => series.data.length !== chartData?.labels.length)) {
        console.error('Error: Mismatch in data lengths for series and labels');
        setChartData({});
        return;
      }

      setChartData({
        labels: chartData.labels.map(date => moment(date).format('MM/DD/YYYY')),
        series: chartData.series,
      });
    } catch (error) {
      console.error('Error fetching product stats:', error);
      setChartData({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!postId) return;
    fetchLikesAndCommentsOverTime(postId);
  }, [postId]);

  const options = useMemo(() => ({
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
        show: true,
        offsetX: 0,
        offsetY: -38,
        selection: true,
        zoom: true,
        pan: true,
      },
    },
    xaxis: {
      type: 'datetime',
      min: chartDefaultDateRange().startOfYear,
      max: chartDefaultDateRange().endOfYear,
    },
    dataLabels: {
      enabled: false,
    },
  }), []);

  const chartOptions = useChart({
    colors: [],
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    fill: {
      type: chartData.series ? chartData.series.map(series => series.fill) : [],
    },
    labels: chartData.labels || [],
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => (value ? `${value.toFixed(0)}` : value),
      },
    },
    ...options,
  });

  return (
    <Box>
      {loading ? (
        <Box display="flex" justifyContent="center" py={2}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-end" py={2}>
            {/* <FilterButtonGroup onPeriodChange={handleDurationSelect} /> */}
            <MUITooltip title="View Post">
              <IconButton variant="contained" onClick={() => window.open(postUrl, '_blank')}>
                <VisibilityIcon />
              </IconButton>
            </MUITooltip>
          </Box>

          {chartData.series && chartData.series.length > 0 ? (
            <Box sx={{ p: 3, pb: 1 }}>
              <Chart
                dir="ltr"
                type="line"
                series={chartData.series}
                options={chartOptions}
                width="100%"
                height={385}
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" sx={{ textAlign: 'center' }} width="100%" my={4}>
              <Typography variant="subtitle2">No data available</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PostLikeCommentChart;
