import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';


const MUIModal = ({ open, handleClose, title, children, maxWidth='sm', fullWidth = true, dialogContentStyle }) => {
  
  return (
    <Dialog 
      open={open} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(event);
        }
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle 
      // mb={1}
      >
        {title && <Typography variant="body1" fontWeight="bold">{title}</Typography>}
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* <Divider/> */}
      <DialogContent sx={dialogContentStyle}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default MUIModal;
