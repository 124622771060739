import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    SvgIcon,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Chip,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStatusBgColor } from '../../helpers';

export const PostCard = (props) => {
    const { postData, dataType, onEdit, onDelete } = props;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    if (!postData) {
        return null;
    }

    const normalizeData = (data, type) => {
        if (type === 'scheduledPosts') {
            return {
                id: data.id,
                content: data.content,
                tm_scheduled: data.tm_scheduled,
                tm_posted: data.tm_posted,
                channel: data.channel,
                product: data.channel?.product,
                status: data.status,
                publishedAt: data.publishedAt,
                postUrl: null,
            };
        } else if (type === 'posts') {
            const { attributes } = data;
            const channelData = attributes.channel?.data?.attributes;
            const productData = channelData?.product?.data?.attributes;
            return {
                id: data.id,
                content: attributes.content,
                tm_scheduled: attributes.tm_scheduled,
                tm_posted: attributes.tm_posted,
                channel: channelData,
                product: productData,
                status: attributes.status,
                publishedAt: attributes.publishedAt,
                postUrl: attributes.post_url,
            };
        }
        return null;
    };

    const renderData = normalizeData(postData, dataType);

    if (!renderData) {
        return null;
    }

    let socialMediaType;
    if (renderData.channel?.channel_type?.includes('facebook')) {
        socialMediaType = <FacebookIcon />;
    } else if (renderData.channel?.channel_type?.includes('instagram')) {
        socialMediaType = <InstagramIcon />;
    }

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = () => {
        onDelete(renderData.id);
        setOpenDeleteDialog(false);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            pb: 1,
                        }}
                    >
                        <Typography align="center" gutterBottom variant="title">
                            {renderData.product?.name}
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> {/* Adjust gap here */}
                            {(renderData.status === 'waiting' || renderData.status === 'draft' || renderData.status === 'errored') && (
                                <Tooltip title="Edit Post" sx={{ cursor: 'pointer' }}>
                                    <IconButton onClick={() => onEdit(postData)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {renderData.status === 'published' && (
                                <Tooltip title="View Post" sx={{ cursor: 'pointer' }}>
                                    <IconButton onClick={() => window.open(renderData.postUrl, '_blank')}>
                                        <LinkIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Tooltip title="Delete Post" sx={{ cursor: 'pointer' }}>
                                <IconButton onClick={handleDeleteClick}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            pb: 3,
                        }}
                    >
                        {socialMediaType && (
                            <SvgIcon color="action" sx={{ mr: 1 }}>
                                {socialMediaType}
                            </SvgIcon>
                        )}
                        <Typography align="center" gutterBottom variant="body1">
                            {renderData.channel?.display_name}
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1">
                        {renderData.content}
                    </Typography>
                </CardContent>
                <Box sx={{ flexGrow: 1 }} />
                <Divider />
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <SvgIcon color="action" fontSize="small">
                            <AccessTimeIcon />
                        </SvgIcon>
                        <Typography color="text.secondary" display="inline" variant="body2">
                            {renderData.status === 'published' && <Tooltip title={`${moment(renderData.publishedAt).format('lll')}`} sx={{ cursor: 'pointer' }}>{moment(renderData.publishedAt).fromNow()}</Tooltip>}
                            {(renderData.status === 'waiting' || renderData.status === 'draft' || renderData.status === 'errored') && <Tooltip title={`${moment(renderData.tm_scheduled).format('lll')}`} sx={{ cursor: 'pointer' }}>{moment(renderData.tm_scheduled).fromNow()}</Tooltip>}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <SvgIcon color="action" fontSize="small">
                            <QueryStatsIcon />
                        </SvgIcon>
                        <Chip size="small" label={renderData.status} style={{ color: '#FFFFFF', backgroundColor: getStatusBgColor(renderData.status.toLowerCase()) }} />
                    </Stack>
                </Stack>
            </Card>

            <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this post? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

PostCard.propTypes = {
    postData: PropTypes.object.isRequired,
    dataType: PropTypes.oneOf(['scheduledPosts', 'posts']).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
